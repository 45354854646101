'use client'

import * as React from 'react'
import { trackPageView } from 'analytics/amplitude/utils'
import { facebookEvent, FacebookEventName } from 'analytics/facebook'

import { usePathname } from '../utils/navigation'
import { useSiteBanner } from '../hooks/use-site-data'

export function RouteChangeListeners({
  skipAmplitudePageViewTracking = false,
}: {
  skipAmplitudePageViewTracking?: boolean
}) {
  const pathname = usePathname()
  const { banner, isFetched: bannerIsFetched } = useSiteBanner()

  React.useEffect(() => {
    if (!skipAmplitudePageViewTracking && bannerIsFetched) {
      trackPageView({
        banner_above_nav:
          banner && 'bannerAboveNav' in banner ? banner.bannerAboveNav : 'no_banner',
        banner_above_nav_state:
          banner && 'bannerAboveNavState' in banner ? banner.bannerAboveNavState : 'no_banner',
      })
    }
  }, [pathname, skipAmplitudePageViewTracking, banner, bannerIsFetched])

  React.useEffect(() => {
    facebookEvent(FacebookEventName.PAGE_VIEW)
  }, [pathname])

  return <></>
}
