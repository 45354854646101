//Docs on https://next-intl-docs.vercel.app/docs/routing/navigation#link
import { createSharedPathnamesNavigation } from 'next-intl/navigation'
import { locales, DEFAULT_LOCALE } from 'utils/locales'

export const { Link, redirect, usePathname, useRouter } = createSharedPathnamesNavigation({
  locales,
  localePrefix: 'as-needed', //this should match the value in middleware.ts
})

export const addLocaleToPath = (path: string, locale: string) => {
  const sanitizedPath = `${path.startsWith('/') ? '' : `/`}${
    path.endsWith('/') ? path : `${path}/`
  }`

  if (locale === DEFAULT_LOCALE || !locales.includes(locale)) {
    return sanitizedPath
  }

  return `/${locale}${sanitizedPath}`
}
