import { useSiteDataQuery } from 'codegen/generated/cf'
import {
  NewsletterPopup,
  NotificationPopup,
  ProfileNudgePopup,
  AwaitingRenewalConfirmationBanner,
  ClassesBanner,
  NotificationBanner,
  OnHoldSubscriptionBanner,
  SparkFreeTrialBanner,
  AllAccessBanner,
  UndoCancelationBanner,
  UpsellBanner,
  VerificationBanner,
  AllAccessYearlyBanner,
  AllAccessYearlyExtendBanner,
} from 'codegen/types'

export type Popups = NewsletterPopup | NotificationPopup | ProfileNudgePopup
export type Banners =
  | AwaitingRenewalConfirmationBanner
  | ClassesBanner
  | NotificationBanner
  | OnHoldSubscriptionBanner
  | SparkFreeTrialBanner
  | AllAccessBanner
  | UndoCancelationBanner
  | UpsellBanner
  | VerificationBanner
  | AllAccessYearlyBanner
  | AllAccessYearlyExtendBanner

export const siteDataQueryOptions = {
  keepPreviousData: true,
  staleTime: Infinity,
}

// eslint-disable-next-line local/codegen-query-returns
export function useSiteData() {
  return useSiteDataQuery(undefined, {
    ...siteDataQueryOptions,
    select(data) {
      return data.siteData
    },
  })
}

export function useSitePopup() {
  const { data } = useSiteData()

  let popup: Popups | null = null

  if (data?.newsletterPopup) {
    popup = data.newsletterPopup
  }

  if (data?.notificationPopup) {
    popup = data.notificationPopup
  }

  if (data?.profileNudgePopup) {
    popup = data.profileNudgePopup
  }

  return popup
}

export function useSiteBanner() {
  const { data, isFetched } = useSiteData()
  let banner: Banners | null = null

  if (data?.awaitingRenewalConfirmationBanner) {
    banner = data.awaitingRenewalConfirmationBanner
  }

  if (data?.classesBanner) {
    banner = data.classesBanner
  }

  if (data?.notificationBanner) {
    banner = data.notificationBanner
  }

  if (data?.onHoldSubscriptionBanner) {
    banner = data.onHoldSubscriptionBanner
  }

  if (data?.sparkFreeTrialBanner) {
    banner = data.sparkFreeTrialBanner
  }

  if (data?.allAccessBanner) {
    banner = data.allAccessBanner
  }

  if (data?.undoCancelationBanner) {
    banner = data.undoCancelationBanner
  }

  if (data?.upsellBanner) {
    banner = data.upsellBanner
  }

  if (data?.verificationBanner) {
    banner = data.verificationBanner
  }

  if (data?.allAccessYearlyBanner) {
    banner = data.allAccessYearlyBanner
  }

  if (data?.allAccessYearlyExtendBanner) {
    banner = data.allAccessYearlyExtendBanner
  }

  return { banner, isFetched }
}

export function useIsStripeBillingEnabled() {
  const { data } = useSiteData()
  return Boolean(data?.billing)
}

export function useRefetchSiteData() {
  const { refetch } = useSiteDataQuery()

  return refetch
}
