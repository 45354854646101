import invariant from 'tiny-invariant'

// https://developers.facebook.com/docs/facebook-pixel/reference
export enum FacebookEventName {
  COMPLETE_REGISTRATION = 'CompleteRegistration',
  PAGE_VIEW = 'PageView',
  VIEW_CONTENT = 'ViewContent',
  SEARCH = 'Search',
}

export type FacebookEventOptions = { [key: string]: string | string[] } | void
export type FacebookEventFunction = (
  track: 'track',
  name: FacebookEventName,
  options: FacebookEventOptions,
) => void

declare global {
  interface Window {
    fbq?: FacebookEventFunction
  }
}

const pixelId = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID || process.env.VITE_FACEBOOK_PIXEL_ID

invariant(pixelId, 'FACEBOOK_PIXEL_ID must be set inside FacebookPixel')

export const initScript = `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');

fbq('init', '${pixelId}');

fbq('track', 'PageView');
`

export const FacebookPixel = () => {
  return (
    <noscript>
      <img
        loading="lazy"
        height="1"
        width="1"
        style={{ display: 'none' }}
        alt="Facebook Pixel"
        src={`https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`}
      />
    </noscript>
  )
}

export const facebookEvent = (name: FacebookEventName, options: FacebookEventOptions) => {
  if (!window.fbq) {
    return
  }

  window.fbq('track', name, options)
}
